<template>
  <div class="hello">
    <h1 style="margin-bottom: 60px;">{{ welcome }}</h1>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  data() {
    return {
      welcome: "欢迎使用"
    }
  }
}


</script>

<style>
.hello {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px
}
</style>